<template>
  <div class="main">
    <div class="title">修改检测</div>
    <el-form :model="ruleForm"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm">
      <el-form-item label="检测报告"
                    style="width: 20%;"
                    prop="detection_name">
        <el-input placeholder="请输入"
                  v-model="self.detection_name"></el-input>
      </el-form-item>
      <el-form-item label="科目"
                    style="width: 20%;"
                    prop="subject_ids">
        <el-select style="width:100%"
                   v-model="self.subject_ids"
                   clearable
                   multiple
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="goback()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAllSubject } from '@/api/getSubjectList.js'
import { addTestReport } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      ruleForm: {
        detection_name: '',
        subject_ids: '',
        user_id: ''
      },
      self: {
        detection_name: '',
      },
      rules: {
        detection_name: [
          { required: true, message: '请输入检测报告', trigger: 'blur' },
        ],
        subject_ids: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
      },
      subjectList: []
    };
  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
  },
  async created () {
    const { data } = await getAllSubject()
    this.subjectList = data.list
    if (this.$route.query.subject_name) {
      let nums = this.$route.query.subject_name.split(",") || ''
      data.list.map((item) => {
        nums.map((k) => {
          if (item.subject_name == k) {
            this.self.subject_ids.push(item.subject_id)
          }
        })
      })
    }

    this.self.detection_name = this.$route.query.detection_name
  },
  methods: {
    submitForm (formName) {
      this.self.user_id = Number(this.$route.query.user_id)
      this.self.subject_ids = this.self.subject_ids.join(',') || ''
      console.log('this.self', this.self)
      this.self.detection_id = this.$route.query.detection_id
      this.$refs[formName].validate((valid) => {
        let params = this.self
        console.log('params', params)
        if (valid) {
          this.$http({
            url: '/api/v1/tang/create_detection',
            method: 'post',
             data:params
          }).then(res => {
            this.$notify({
              title: '提示',
              message: '提交成功!',
              type: 'success'
            });
            this.resetForm(formName)
            this.$router.push(
              {
                path: '/dataAnalysis/report',
                // query: {
                //   user_id: row.id,
                //   user_paper_id: row.user_paper_id
                // }
              })
          })

        } else {
          this.$notify({
            title: '提示',
            message: '提交失败!',
            type: 'error'
          });
          return false;
        }
      });
    },
    // async postData (params) {
    //   const { data } = await addTestReport(params)
    // },
    resetForm (formName) {
      this.$refs[formName].resetFields();

    },
    goback () {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  font-family: Regular;
  padding: 12px;
  background: white;
  .title {
    top: 142px;
    left: 256px;
    width: 80px;
    height: 30px;
    // background: rgba(17, 17, 17, 1);
    margin: 8px 0px 30px 12px;
    color: rgba(17, 17, 17, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}
::v-deep .el-button {
  padding: 0;
  width: 60px;
  height: 32px;
  margin-top: 16px;
}

::v-deep .el-form-item__content {
  margin-bottom: 6px;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
.pedding {
  color: green;
}
</style>