import { instance, http_file } from '@/utils/http.js'

/**
 *  自主组卷  考点组卷 获取科目  根据年级 班级 班级年级二选一
 */
export const getByRadeClassSubject = function (data) {
  return instance({
    url: '/api/v1/public/class_grade_subject',
    method: 'get',
    params: data
  })
}

// 获取教师的科目
/**
 * @param edu_user_id 教师id获取科目  如果不传默认获取账号教师科目
 */
export const getTeacherSubjects = function (params = {}) {
  return instance({
    url: '/api/v1/main/research_subjects',
    method: 'get',
    params
  })
}


/**
 * @param 获取全部科目
 * 
 */
export const getAllSubject = function () {
  return instance({
    url: '/api/v1/school/get_subject',
    method: 'get'
  })
}

/***
 * 获取教师科目
 * 
 */

export const getTeacherSubjectList = function () {
  return instance({
    url: '/api/v1/public/teacher_subject_lst',
    method: 'get'
  })
}
